import React, { useEffect, useRef, useState } from 'react'
import classes from './ImageReveal.module.scss'
import Image from 'next/image'
import DragHandleOutlined from '@mui/icons-material/DragHandleOutlined'
import useScreenSize from '@/hooks/useScreenSize'

const ImageReveal = ({
  firstImage,
  secondImage,
  hoverFeature,
  parentOnMove,
  showHandle,
  centerResizer,
}) => {
  const [firstImageLoaded, setFirstImageLoaded] = useState(false)
  const [secondImageLoaded, setSecondImageLoaded] = useState(false)
  const secondImageRef = useRef(null)
  const { width } = useScreenSize()
  const resizerRef = useRef(null)

  const handleMove = (event) => {
    if (secondImageRef?.current?.children && resizerRef?.current) {
      const resizer = resizerRef?.current
      const [topImage] = secondImageRef?.current?.children
      if (resizer && topImage) {
        const distance = event.clientX - resizer.getBoundingClientRect().x
        updateImagePosition(distance)
      }
    }
  }

  const handleTouchMove = (event) => {
    const [touch] = event.touches
    const resizer = resizerRef.current
    const [topImage] = secondImageRef.current?.children
    if (resizer && topImage) {
      const distance = touch.clientX - resizer.getBoundingClientRect().x
      updateImagePosition(distance)
    }
  }

  const updateImagePosition = (distance) => {
    const resizer = resizerRef.current
    const [topImage] = secondImageRef.current?.children
    topImage.style.clipPath = `inset(0 0 0 ${resizer.offsetLeft + distance}px)`
    topImage.style.visibility = 'visible'

    if (
      resizer.parentElement &&
      resizer.offsetLeft + distance < resizer.parentElement.clientWidth
    ) {
      resizer.style.left = `${resizer.offsetLeft + distance}px`
    } else if (resizer.offsetLeft < 4) {
      resizer.style.left = '0px'
    }
  }
  const alignResizerAtCenter = () => {
    const resizer = resizerRef.current
    const containerWidth = resizer.parentElement.clientWidth
    const resizerWidth = resizer.clientWidth
    if (resizer && containerWidth && resizerWidth) {
      const centerOffset = (containerWidth - resizerWidth) / 2
      resizer.style.left = `${centerOffset}px`
      updateImagePosition(10)
    }
  }

  useEffect(() => {
    if (parentOnMove) {
      handleMove(parentOnMove)
    }
  }, [parentOnMove, centerResizer])

  useEffect(() => {
    if (
      centerResizer &&
      firstImageLoaded &&
      secondImageLoaded &&
      resizerRef?.current
    ) {
      alignResizerAtCenter()
    }
  }, [centerResizer, firstImageLoaded, secondImageLoaded, width])

  return (
    <div className={classes['image-reveal']}>
      <div
        className={classes['image-reveal__container']}
        onMouseMove={handleMove}
        onTouchMove={handleTouchMove}
      >
        <Image
          layout="fill"
          draggable={false}
          className={classes['image-reveal__container__base-image']}
          src={firstImage}
          alt="Primary Image"
          onLoad={() => setFirstImageLoaded(true)}
        />
        {hoverFeature && secondImage && (
          <div ref={secondImageRef}>
            <Image
              layout="fill"
              draggable={false}
              className={classes['image-reveal__container__top-image']}
              src={secondImage}
              alt="Secondary Image"
              onLoad={() => setSecondImageLoaded(true)}
            />
          </div>
        )}
        {hoverFeature &&
          secondImage &&
          firstImageLoaded &&
          secondImageLoaded && (
            <div
              className={classes['image-reveal__container__resizer']}
              ref={resizerRef}
            >
              {showHandle ? (
                <button
                  aria-label="drag-button"
                  className={classes['image-reveal__container__resizer-btn']}
                >
                  <DragHandleOutlined style={{ width: 20, height: 20 }} />
                </button>
              ) : (
                <span role="presentation" />
              )}
            </div>
          )}
      </div>
    </div>
  )
}

export default ImageReveal
